const environment = {
  tiles: '/ofp',
  graph: '/dtr/graphql',
  graphCache: '/cache-api/graphql',
  auth: '/dtr',
  fss: '/dtr',
  api: '/dtr',
  // Servers
  serverAddress: 'https://api.dtechs.dev/terra-filter', // '192.168.99.247:8080' 'http://192.168.99.172:12344 without api
  serverAddressMah: 'https://api.dtechs.dev/mh-ds',
  serverAddressKPT: 'https://gis.stage.dtechs.dev/kpt-filter',
  serverAddressKptStorage: 'https://api.dtechs.dev/kpt-storage',
  serverAddressRGS: 'http://10.71.134.21',
  prod: 'http://10.71.134.21/',
  local: 'http://192.168.99.172:12344', // + api
  // Versions'
  v1: '/api/v1',
  v2: '/api/v2',
};
export default environment;
